.l-full-width {
    @include container(100%);
    position: relative;
    padding: 0 15px;
}

.l-responsive {
    @include container($screen-md);
    position: relative;
    padding: 0 15px;

    // @include breakpoint('max', $screen-lg) {
    //     @include container($screen-md);
    // }
    @include breakpoint('max', $screen-md) {
        @include container($screen-sm);
    }
    @include breakpoint('max', $screen-sm) {
        @include container(100%);
    }
}

.l-responsive-with-large {
    @include container($screen-lg);
    position: relative;
    padding: 0 15px;

    @include breakpoint('max', $screen-lg) {
        @include container($screen-md);
    }
    @include breakpoint('max', $screen-md) {
        @include container($screen-sm);
    }
    @include breakpoint('max', $screen-sm) {
        @include container(100%);
    }
}
