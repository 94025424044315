.u-h1 {
    @include responsiveType($font-size-h1);
}

.u-h2 {
    @include responsiveType($font-size-h3);
    color: $color-primary;
    font-weight: 600;
}

.u-h3 {
    @include responsiveType($font-size-h5);
    font-weight: 700;
}

.u-hidden-lg {
    @include breakpoint('min', $screen-lg) {
        display: none;
    }
}

.u-no-margin {
    margin: 0;
}

hr {
    height: 1px;
    border: 0;
    background-color: $color-primary;
}
