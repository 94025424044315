// Tweaked copypasta from passit-frontend repo (after webpack built it)
.form-field {
    position:relative;
    margin-bottom:20px;
}

.form-field__label {
    display:block;
    margin-bottom:5px;
    color:#413741;
    font-size:.8em;
    font-weight:600;
}

.form-field__input {
    width:100%;
    padding:8px;
    transition:box-shadow .2s;
    border:1px solid #BED2D2;
    background-color:#FFFFFF;
    color:#413741;
    box-shadow:inset 0 0 0 0 #C0F0FF;
}

.form-field__input--one-action {
    padding-right:38px;
}

.form-field__input--one-action:focus {
    padding-right:45px;
}

.form-field__input--two-actions {
    padding-right:70px;
}

.form-field__input--two-actions:focus {
    padding-right:77px;
}

.form-field__textarea {
    width:100%;
    height:100px;
    padding:8px;
    transition:box-shadow margin .2s .2s;
    border:1px solid #BED2D2;
    background-color:#FFFFFF;
    color:#413741;
    box-shadow:inset 0 0 0 0 #C0F0FF;
}

.form-field--dirty-feedback-next.ng-dirty+.form-field__input,.form-field--dirty-feedback.ng-dirty,.form-field__input:focus,.form-field__textarea:focus {
    border:1px solid #D0E6E6;
    border-bottom-color:#413741;
    outline:0;
    background-color:#FFFFFF;
    box-shadow:inset 0 -4px 0 0 #C0F0FF;
}

.form-field__input--invalid {
    border-bottom-color:#B92855;
    box-shadow:inset 0 -1px 0 0 #B92855;
}

.form-field__input--invalid:focus {
    border:1px solid #DBC5BD;
    border-bottom-color:#B92855;
    box-shadow:inset 0 -4px 0 0 #EEC8BA;
}

.form-field--dirty-feedback.ng-dirty:not(:focus),.form-field--dirty-feedback.ng-dirty:not(:focus)+.form-field__input {
    border-bottom-color:#0092A8;
    box-shadow:inset 0 -3px 0 0 #0092A8;
}

.form-field--large .form-field__label {
    font-size:1.2em;
}

.form-field--large .form-field__input {
    padding-top:12px;
    padding-bottom:12px;
    font-size:1.25em;
}

.form-field--large .form-field__input:focus {
    box-shadow:inset 0 -6px 0 0 #C0F0FF;
}

.form-field--large .form-field__input--invalid {
    box-shadow:inset 0 -2px 0 0 #B92855;
}

.form-field--large .form-field__input--invalid:focus {
    box-shadow:inset 0 -6px 0 0 #EEC8BA;
}

.form-field--large .form-field__action {
    width:32px;
    height:48px;
}

.form-field__select {
    display:inline-block;
    position:relative;
    border-bottom:1px solid #413741;
}

.form-field__select__field {
    padding:10px 35px 10px 0;
    border:0;
    background-color:transparent;
    appearance:none;
}

.form-field__select:after {
    position:absolute;
    top:0;
    right:0;
    padding:10px;
    transform:scaleY(.67);
    color:#413741;
    content:"\25BC";
    z-index:-1;
}

.form-field__note {
    margin-top:10px;
    color:#413741;
    line-height:1.4;
}

.form-field__checkbox {
    color:#413741;
    cursor:pointer;
    user-select:none;
}

.form-field__checkbox:hover .form-field__checkbox__input+.form-field__checkbox__check {
    border-color:#C0F0FF;
    box-shadow:inset 0 0 0 0 #C0F0FF,0 0 0 2px #C0F0FF;
}

.form-field__checkbox:hover .form-field__checkbox__check--checked svg,.form-field__checkbox:hover .form-field__checkbox__input:checked+.form-field__checkbox__check svg {
    transform:scale(1.1);
}

.form-field__checkbox__input {
    display:none;
}

.form-field__checkbox .form-field__checkbox__check--checked,.form-field__checkbox__input:checked+.form-field__checkbox__check {
    border-color:#0092A8;
    background-color:#FFFFFF;
    box-shadow:inset 0 0 0 0 #0092A8,0 0 0 1px #0092A8;
}

.form-field__checkbox .form-field__checkbox__check--checked svg,.form-field__checkbox__input:checked+.form-field__checkbox__check svg {
    transform:scale(1);
    fill:#413741;
}

.form-field__checkbox__check {
    display:inline-block;
    position:relative;
    width:20px;
    height:20px;
    transition:border-color box-shadow .2s .2s;
    border:2px solid #BED2D2;
    box-shadow:inset 0 0 0 0 #0092A8;
    box-sizing:border-box;
}

.form-field__checkbox__check--inline {
    top:4px;
    margin-right:4px;
}

.form-field__checkbox__check--small {
    width:16px;
    height:16px;
    border-width:1px;
}

.form-field__checkbox__check svg {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    transform:scale(0);
    transition:transform .2s cubic-bezier(0,.74,.4,1.43),fill .3s,-webkit-transform .2s cubic-bezier(0,.74,.4,1.43);
    fill:#FFFFFF;
}

.form-field__actions {
    position:absolute;
    right:0;
    bottom:0;
}

.form-field__action {
    width:32px;
    height:36px;
    border:0;
    background-color:transparent;
}

.form-field__error-list {
    margin:-7px 0 30px;
    padding:0;
    list-style:none;
}

.form-field__error {
    color:#B92855;
}
