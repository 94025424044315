.home-feature-container {
    padding: 10px 0 5px;
    background-color: $color-botticelli;

    &--alt {
        background-color: #DFEAEA;
    }

    @include breakpoint('max', $screen-sm) {
        .home-feature__column--content {
            order: 2;
        }
    }
}

.home-feature {
    display: flex;
    padding: 10px 0;

    @include breakpoint('max', $screen-sm) {
        flex-direction: column;
    }

    &--image-right {
        .home-feature__main {
            text-align: right;
        }

        .home-feature__link.active {
            right: auto;
            left: 0;
        }
    }

    &__column {
        width: 50%;

        @include breakpoint('max', $screen-sm) {
            width: 100%;
        }

        &--content {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            padding: 20px;
        }
    }

    &__image {
        width: 75%;
        margin: 0 12.5%;

        @include breakpoint('max', $screen-sm) {
            display: block;
            width: 100%;
            max-width: 200px;
            margin: 0 auto;
        }

        &--everywhere {
            width: 80%;
            margin: 30px 10%;
            box-shadow: 0 4px 7px 0 rgba($color-thunder, 0.25);
        }

        @include breakpoint('max', $screen-sm) {
            max-width: 400px;
            margin: 10px auto 20px;
        }
    }

    &__heading {
        color: $color-thunder;
        font-size: 32px;

        @include breakpoint('max', $screen-sm) {
            margin-bottom: 20px;
            line-height: 1;
            text-align: center;
        }
    }

    &__main {
        @include breakpoint('max', $screen-sm) {
            max-width: 400px;
            margin: 0 auto;
            text-align: center;
        }
    }

    &__extra {
        font-size: 0.9em;
        @include breakpoint('max', $screen-sm) {
            max-width: 400px;
            margin: 10px auto;
        }
    }

    &__link {
        display: inline-block;
        clear: both;
        font-size: 0.7em;

        &--collapse {
            display: none;
        }

        &.active {
            .home-feature__link--expand {
                display: none;
            }

            @include breakpoint('min', $screen-sm-min) {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 1.3em;
                box-shadow: 0 0 0 0 transparent;
            }
            @include breakpoint('max', $screen-sm) {
                .home-feature__link--collapse {
                    display: inline;
                }
            }
        }
    }

    &__list {
        margin: 5px 0;
        padding-left: 20px;

        @include breakpoint('max', $screen-sm) {
            margin-top: 10px;
            text-align: left;
        }
    }

    &__list-item {
        margin-bottom: 0.228571429em;
        line-height: 1.2em;

        @include breakpoint('max', $screen-sm) {
            margin-bottom: 0.5em;
        }
    }
}

.everywhere-read-more,
.secure-read-more,
.trustworthy-read-more,
.useful-read-more {
    height: 0;
    transition: opacity 0.5s;
    opacity: 0;
    overflow: hidden;

    &.active {
        height: auto;
        opacity: 1;
    }
}
