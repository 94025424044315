// Tweaked copypasta from passit-frontend repo (after webpack built it)
.text-link, a {
    text-decoration: none;
}

.text-link {
    transition: color .2s, box-shadow .2s;
    color: #0092A8;
    font-weight: 600;
    box-shadow: 0 1px 0 0 #0092A8;
}

.text-link:hover {
    color: #006675;
    box-shadow: 0 1px 0 0 #006675;
}

.text-link--white {
    color: #FFFFFF;
    box-shadow: 0 1px 0 0 #FFFFFF;
}

.text-link--white:hover {
    color: #FFFFFF;
    box-shadow: 0 1px 0 0 #FFFFFF;
}

.text-link--large {
    box-shadow: 0 2px 0 0 #0092A8;
}

.text-link--large:hover {
    box-shadow: 0 2px 0 0 #006675;
}

.text-link--caret:after,
.text-link--expand:after {
    position: relative;
    left: 0;
    padding-right: 1px;
    padding-left: 5px;
}

.text-link--caret:hover:after {
    left: 3px;
}

.text-link--caret:after {
    transition: left .2s;
    content: "\203A";
}

.text-link--expand:after {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin-right: -0.2em;
    padding: 0;
    content: "+";
}

.text-link--expand.active:after {
    content: "\00D7";
}

.text-link--left-caret:before {
    position: relative;
    left: 0;
    padding-right: 5px;
    padding-left: 1px;
    transition: left .2s;
    content: "\2039\A0";
}

.text-link--left-caret:hover:before {
    left: -3px;
}
