body {
    @include responsiveType($font-size-base);
    font-family: $font-sans-serif;
    line-height: 1.428571429;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.svg-symbols {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
}

// My current belief is that <h*> tags are only semantically necessary. They 
// should be used with regards to document structure only. An 'h3' could look
// like an 'h2' or an 'h1', so none of these should have default styling.
// Class-based styling is the name of the game. We'll probably end up defining
// '.h1' styles or something like that as we move on.
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

// Not 100% set on this, but I'm doing it for the same reasons as the headers
a {
    color: inherit;
    text-decoration: none;
}

button, input[type="submit"] {
    border: 0;
    appearance: none;
}

.home-page-content {
    margin-top: 40px;
}

// BEM doesn't work here
.markdown-container {
    padding: 20px 15px;

    h1 {
        @extend .heading-main;
        margin-bottom: 20px;
    }
    h2 {
        @extend .heading-subheading;
        margin-bottom: 20px;
        // color: $color-thunder;
        // font-weight: 700;
    }
    h3 {
        @extend .heading-3;
        margin-bottom: 20px;
        // @include responsiveType($font-size-h4);
        // color: #0092A8;
        // font-weight: 600;
    }
    a {
        @extend .text-link;
    }
    img {
        max-width: 100%;
    }
    strong {
        font-weight: 600;
    }
    code {
        background-color: #E8FFFF;
        font-family: 'Overpass Mono';
        font-size: 0.8em;
    }
    .highlighter-rouge .highlight {
        background-color: #E8FFFF;
    }
}

.mm-signed-up {
    display: none;
    padding: 10px 0;
    background-color: $color-de-york;//#FFCF00;
    color: $color-white;
    font-size: 0.8em;
    font-weight: 600;
    text-align: center;
    box-shadow: 0px 1px 4px 0px rgba($color-thunder, 0.5);
    z-index: 5;

    &--active {
        display: block;
    }
}

.site-footer {
    margin-top: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $color-onahau;
    font-size: 0.7em;
    text-align: center;
}
