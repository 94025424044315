.security-model {
    padding: 30px 15px 20px;

    &__title,
    &__header {
        margin-bottom: 20px;
    }
}

.diagram {
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    @include breakpoint('max', $screen-md) {
        display: block;
        margin-bottom: 100px;
    }
}

.client-side__cutoff {
    display: none;
    position: relative;
    margin-top: 80px;
    margin-bottom: -60px;
    z-index: 0;

    @include breakpoint('max', $screen-md) {
        display: block;
        max-width: 600px;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;

        &:before {
            @include jaggedEdge;
            position: absolute;
            left: 0;
            width: 100%;
            height: 20px;
            content: '';
            z-index: 1;
        }
    }
}

.client-side {
    width: 50%;
    border: 1px solid #BCBCBC;
    border-radius: 10px;
    background-color: #EFEFEF;

    @include breakpoint('min', $screen-lg) {
        border-radius: 1px;
    }
    @include breakpoint('max', $screen-md) {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;

        .client-side__cutoff & {
            transform: translateY(-50%);
        }
    }
}

.client-side__screen {
    margin: 10px 30px;
    border: 1px solid #BCBCBC;
    background-color: white;
    
    @include breakpoint('min', $screen-lg) {
        margin: 30px 30px;
    }
    @include breakpoint('max', $screen-xs) {
        margin: 30px 5px;
    }

    .client-side__cutoff & {
        height: 70px;
        background-color: #272822;
    }
}
.client-side__user-interaction {
    padding: 15px 10px 5px;
    font-size: 16px;
    text-align: center;
}

.client-side__passit-banner {
    height: 25px;
    background-color: $color-primary;
    text-align: center;
}
.passit-banner__logo {
    position: relative;
    top: -5px;
    width: 50px;
    height: 12px;
    fill: white;
}
    
.user-interaction__label {
}

.user-interaction__field {
    margin: 0 10% 10px;
    padding: 5px 15px;
    border: 1px solid gray;
    color: $color-primary;
}

.client-side__under-the-hood {
    padding: 25px 10px 10px;
    background-color: #272822;
    color: white;
    font-family: monospace;
    font-size: 16px;
    @include jaggedEdge;

    @include breakpoint('max', $screen-lg) {
        font-size: 14px;
    }
    @include breakpoint('max', $screen-md) {
        font-size: 16px;
    }
}

.under-the-hood__list {
    padding-left: 25px;
    list-style: none;
    text-indent: -18px;
}
.under-the-hood__list-item {
    &:before {
        content: '-\00A0';
    }
}

.under-the-hood__list-item--no-bullet {
    &:before {
        content: '\00A0';
    }
}

.tubes-and-server {
    width: 50%;

    @include breakpoint('max', $screen-md) {
        width: 100%;
    }
}

.tubes-and-server__group {
    display: flex;
    align-items: center;
    width: 100%;

    @include breakpoint('max', $screen-md) {
        display: block;
    }
}

.internet-tubes {
    width: 50%;
    text-align: center;

    @include breakpoint('max', $screen-md) {
        display: flex;
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
        @include clearfix;
    }
}

.internet-tubes__item {
    position: relative;
    margin: 30px 20px;
    padding-bottom: 3px;
    font-size: 18px;
    line-height: 1.2;

    @include breakpoint('max', $screen-md) {
        width: 50%;
        margin: 20px 0;
        padding: 40px 0;
        float: left;
        // Is this better than adding another wrapper div?
        // Don't know. Highlighting that text is pretty funky though!
        text-shadow: 1px 1px 0 white, -1px -1px 0 white, 
        1px -1px 0 white, -1px 1px 0 white, 
        2px 2px 0 white, -2px -2px 0 white,
        2px -2px 0 white, -2px 2px 0 white, 
        3px 3px 1px white, -3px -3px 1px white,
        3px -3px 1px white, -3px 3px 1px white;
        z-index: 0;
    }
}

.internet-tubes__item:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: black;
    content: '';

    @include breakpoint('max', $screen-md) {
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        z-index: -1;
    }
}

.internet-tubes__item--input:before, 
.internet-tubes__item--output:before {
    position: absolute;
    bottom: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    content: '';

    @include breakpoint('max', $screen-md) {
        left: 50%;
        margin-left: -7px;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
    }
}
.internet-tubes__item--input:before {
    right: 0;
    border-left: 10px solid black;

    @include breakpoint('max', $screen-md) {
        border-top: 10px solid black;
        border-left: 7px solid transparent;
    }
}
.internet-tubes__item--output:before {
    left: 0;
    border-right: 10px solid black;

    @include breakpoint('max', $screen-md) {
        top: -7px;
        left: 50%;
        border-right: 7px solid transparent;
        border-bottom: 10px solid black;
    }
}



.server-side {
    $cylinder-cap-height: 70px;

    position: relative;
    width: 50%;
    margin: ($cylinder-cap-height / 2) 0;
    padding: ($cylinder-cap-height / 2 + 20) 20px 0;
    border: 1px solid #BCBCBC;
    background-color: #EFEFEF;
    font-size: 20px;
    z-index: 0;

    @include breakpoint('max', $screen-lg) {
        font-size: 18px;
    }
    @include breakpoint('max', $screen-md) {
        width: 100%;
        max-width: 300px;
        margin-right: auto;
        margin-left: auto;
    }

    &:before, &:after {
        position: absolute;
        left: -1px;
        width: calc(100% + 2px);
        height: $cylinder-cap-height;
        border: 1px solid #BCBCBC;
        background-color: #EFEFEF;
        content: '';
    }

    &:before {
        top: -($cylinder-cap-height / 2);
        border-radius: 50%;
    }

    &:after {
        @include clearfix;
        bottom: -($cylinder-cap-height / 2);
        border-top: 0;
        border-radius: 0 0 50% 50%;
        z-index: -1;
    }

    &:first-child {
        z-index: 1;
    }
}

.server-side__list {
    padding-left: 20px;
}

.one-off-blocks {
    @include container;
    margin-top: 20px;

    &__inner {
        @include breakpoint('min', $screen-sm) {
            @include span(6 of 12 0.4);
            position: relative;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__block-group {
        &--1 {
            position: relative;
            @include breakpoint('min', $screen-lg) {
                @include span(5 of 12 1);
            }
        }

        &--2 {
            position: relative;
            @include breakpoint('min', $screen-lg) {
                @include span(7 of 12 1 last);
            }
        }

        &--3 {
            position: relative;
            @include breakpoint('min', $screen-lg) {
                @include span(6 of 12 1);
            }
        }

        &--4 {
            position: relative;
            @include breakpoint('min', $screen-lg) {
                @include span(6 of 12 1 last);
            }
        }
    }

    &__block {
        position: relative;
        padding: 20px;
        border: 1px solid #BCBCBC;
        background-color: #EFEFEF;
        font-size: 20px;

        &__list {
            padding-left: 20px;
        }

        &--group {
            width: calc(100% - 20px);
            margin-top: 70px;
            @include breakpoint('max', $screen-lg) {
                margin-top: 20px;
            }
            
            &:before, 
            &:after {
                position: absolute;
                width: 100%;
                height: 100%;
                border: 1px solid #BCBCBC;
                background-color: #EFEFEF;
                content: '';
            }
            &:before {
                top: -10px;
                left: 10px;
                z-index: -1;
            }
            &:after {
                top: -20px;
                left: 20px;
                z-index: -2;
            }
        }
    }

    &__between {
        position: relative;
        margin: 50px 20px 20px 0;
        font-size: 18px;

        @include breakpoint('max', $screen-lg) {
            margin: 20px 20px 10px;
        }

    }
}



.group-connect {
    position: relative;
    left: 50%;
    width: 1px;
    height: 70px;
    background-color: black;
    
    &--1-2-bridge, &--3-4-bridge {
        @include breakpoint('min', $screen-lg) {
            position: absolute;
            top: 50px;
            left: -10px;
            height: 250px;
            transform: skewX(7deg);
        }
    }
    
    &--2-3-bridge {
        @include breakpoint('min', $screen-sm) {
            position: absolute;
            top: 100px;
            left: -9px;
            height: 300px;
            transform: skewX(-4deg);
        }
        @include breakpoint('min', $screen-md) {
            top: 100px;
            left: -12px;
            height: 250px;
            transform: skewX(-6deg);
        }
        @include breakpoint('min', $screen-lg) {
            top: 50px;
            left: -16px;
            transform: skewX(-7deg);
        }
    }
}
