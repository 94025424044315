// http://chir.ag/projects/name-that-color/
// https://davidwalsh.name/sass-color-variables-dont-suck

$color-white: #FFFFFF;
$color-black: #000000;

$color-deep-cerulean: #0092A8;
// $color-deep-cerulean: #00869B;
$color-breaker-bay: #589EA6;
// Deep purples
$color-thunder: #413741;
// $color-eggplant: #4B2A4B;
$color-matterhorn: #4B3C4B;
// Green
$color-de-york: #6CC780;
// Light blues
$color-onahau: #C0F0FF;
$color-anakiwa: #A0EBFF;
$color-malibu: #6BE1FF;
// Grays
$color-botticelli: #D0E6E6;
$color-tiara: #BED2D2;
$color-lily-white: #EEFAFF;
// Reds
$color-brick-red: #B92855;
$color-just-right: #EEC8BA;
$color-wafer: #DBC5BD;

$color-primary: $color-deep-cerulean;


$color-primary: $color-deep-cerulean;

// http://brendanberkley.gitlab.io/palette-pad/?c=00869b-eefaff-a0ebff-413741-6cc780-ffffff-ff3246-f5cd28

$font-sans-serif: 'Overpass', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font-size-base:  22px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // 30 //~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // 21 //~12px

$font-size-h1: floor(($font-size-base * 2.6)) !default; // 62 //~36px
$font-size-h2: floor(($font-size-base * 2.15)) !default; // 51 //~30px
$font-size-h3: ceil(($font-size-base * 1.7)) !default; // 41 //~24px
$font-size-h4: ceil(($font-size-base * 1.25)) !default; // 30 //~18px
$font-size-h5: $font-size-base !default; // 24
$font-size-h6: ceil(($font-size-base * 0.85)) !default; // 21 //~12px

$font-mobile-multiplier:  0.75;




//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;

// Small screen / tablet
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;

// Medium screen / desktop
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;

// Large screen / wide desktop
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
