.main-nav {
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
    background-color: $color-deep-cerulean;
    z-index: 2;

    @include breakpoint('max', $screen-sm) {
        padding: 0;
    }

    &--home-page {
        background-color: $color-breaker-bay;

        .main-nav__inner {
            padding-top: 5px;
        }
    }

    &--fixed {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        box-shadow: 0 1px 5px 0px rgba(0,0,0,0.5);
        z-index: 3;

        .main-nav__logo {
            width: 60px;
            height: 25px;
        }

        .main-nav__links {
            @include breakpoint('min', $screen-sm-min) {
                margin-top: 3px;
                font-size: 0.8em;
            }
            @include breakpoint('max', $screen-sm) {
                margin-top: 2px;
            }
        }

        .main-nav__mobile-menu-icon {
            width: 20px;
            height: 20px;
        }

        &.main-nav--home-page {
            background-color: $color-deep-cerulean;

            .main-nav__inner {
                padding-top: 0;
            }
        }
    }

    &__brand {
        display: inline-block;
        margin: 10px auto 0;
        padding: 0;
        float: left;
    }

    &__logo {
        width: 100px;
        height: 40px;
        fill: white;

        @include breakpoint('max', $screen-sm) {
            width: 80px;
            height: 32px;
        }
    }

    &__mobile-menu-trigger {
        display: inline-block;
        height: 33px;
        margin-top: 8px;
        padding: 4px;
        float: right;
        cursor: pointer;

        @include breakpoint('min', $screen-xs-min) {
            display: none;
        }
    }

    &__mobile-menu-icon {
        width: 25px;
        height: 25px;
        fill: $color-white;
    }

    &__mobile-menu-close {
        height: 40px;
        margin-bottom: 10px;
        padding: 10px;
        color: $color-white;
        font-size: 1.5em;
        font-weight: 600;
        line-height: 25px;
        text-align: right;
        cursor: pointer;

        @include breakpoint('min', $screen-xs-min) {
            display: none;
        }
    }

    &__links {
        height: 40px;
        margin-top: 10px;
        float: right;

        @include breakpoint('max', $screen-sm) {
            margin-top: 5px;
        }

        @include breakpoint('max', $screen-xs) {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: auto;
            margin: 0;
            padding: 10px 5px;
            float: none;
            transform: translateY(-100%);
            transition: transform 0.2s;
            background-color: $color-deep-cerulean;
            z-index: 5;

            &.active {
                transform: translateY(0);
            }
        }
    }

    &__link {
        font-size: 0.75em;
        font-weight: 600;

        @include breakpoint('max', $screen-md) {
            font-size: 0.9em;
        }

        @include breakpoint('max', $screen-sm) {
            font-size: 0.75em;
        }

        &--button {
            margin: 0 10px;
            padding: 6px 25px 3px;
            @include breakpoint('max', $screen-xs) {
                margin: 10px;
                font-size: 0.9em;
            }
        }

        &--text {
            display: inline-block;
            margin: 0 10px 0;
            padding: 12px 0 3px;
            transition: border-bottom 0.2s;
            border-bottom: 2px solid transparent;
            color: $color-white;

            &:hover {
                border-bottom: 2px solid $color-white;
            }

            @include breakpoint('max', $screen-xs) {
                display: block;
                padding: 10px;
                font-size: 0.9em;

                &:hover {
                    border-bottom: 2px solid transparent;
                }
            }
        }
    }
}

.mobile-nav-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color-anakiwa, 0.5);
    z-index: 1;

    @include breakpoint('max', $screen-xs) {
        &.active {
            display: block;
        }
    }
}
