.hero {
    @include responsiveType(floor(($font-size-base * 1.15)));
    position: relative;
    padding: 40px 0 20px;
    background-image: radial-gradient(farthest-side at 50% 90%, $color-botticelli, $color-breaker-bay);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    color: $color-white;
    text-align: center;

    &__image {
        max-width: 100%;

        @include breakpoint('max', $screen-lg) {
            max-width: 960px;
        }
        @include breakpoint('max', $screen-md) {
            max-width: 700px;
        }
        @include breakpoint('max', 701px) {
            max-width: 500px;
        }
        @include breakpoint('max', 500px) {
            max-width: 100%;
        }
    }

    &__header {
        @include responsiveType($font-size-h2);
        margin: 0 0 15px;
        font-weight: 700;
        letter-spacing: -0.02em;
        line-height: 1;
    }

    &__copy {
        max-width: 540px;
        margin: 0 auto -50px;
        font-size: 22px;
        text-align: center;

        @include breakpoint('max', $screen-md) {
            margin-bottom: -20px;
            font-size: 18px;
        }
        @include breakpoint('max', 701px) {
            margin-bottom: 0;
        }
    }

    &__cta {
        margin: 15px 0;
        font-size: 0.9em;

        @include breakpoint('min', $screen-xs-min) {
            display: none;
        }
    }

    &__ctas {
        padding: 40px 0;
        background-color: $color-deep-cerulean;
    }

    &__ctas-heading {
        margin-bottom: 40px;
        color: $color-white;
        text-align: center;
    }

    &__ctas-inner {
        display: flex;
        flex-direction: row;

        @include breakpoint('max', $screen-sm) {
            flex-direction: column;
        }
    }

    &__cta-group {
        flex: 1;
        color: $color-white;
        text-align: center;

        @include breakpoint('max', $screen-sm) {
            margin-bottom: 40px;
        }
    }

    &__cta-button {
        min-width: 400px;
        font-size: 22px;

        @include breakpoint('max', $screen-md) {
            min-width: 300px;
        }

        @include breakpoint('max', $screen-sm) {
            width: 100%;
            min-width: 0;
            max-width: 400px;
        }
    }

    &__form-group {
        display: flex;
        max-width: 400px;
        margin: 0 auto 10px;

        @include breakpoint('max', $screen-md) {
            flex-direction: column;
            max-width: 300px;
        }

        @include breakpoint('max', $screen-sm) {
            max-width: 400px;
        }
    }

    &__form-input {
        flex: 1;
        margin-top: 2px;
        margin-bottom: 3px;
        font-size: 20px;

        @include breakpoint('max', $screen-sm) {
            margin: 0 auto 10px;
        }
    }

    &__form-button {
        min-width: 137px;
        font-size: 22px;

        @include breakpoint('max', $screen-md) {
            max-width: 200px;
            margin: 0 auto 10px;
        }
    }

    &__form-text-block {
        max-width: 400px;
        margin: 10px auto 0;
        padding: 0 10px;
        font-size: 18px;

        @include breakpoint('max', $screen-md) {
            max-width: 300px;
        }

        @include breakpoint('max', $screen-sm) {
            max-width: 400px;
        }
    }

    &__form-text {
        display: none;

        &--active {
            display: block;
        }
    }

    &__text-link {
        display: inline-block;
        margin-top: 12px;
        font-size: 18px;
    }
}
