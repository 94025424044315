@mixin responsiveType($font-size, $multiplier: $font-mobile-multiplier, $breakpoint: $screen-sm-max) {
    font-size: $font-size;
    @media screen and (max-width: $breakpoint) {
        font-size: round($font-size * $multiplier);
    }
}

@mixin breakpoint($min-max, $width) {
    @if $min-max == 'max' {
        $width: $width - 1;
    }
    @media screen and (#{$min-max}-width: $width) {
        @content;
    }
}

@mixin jaggedEdge {
    // http://codepen.io/terrymun/pen/nCFqb
    background-image:
    /* Jagged */
    linear-gradient(135deg, rgba(white,1) 0%, rgba(white,1) 50%, rgba(white,0) 50%, rgba(white,0) 100%),
    linear-gradient(-135deg, rgba(white,1) 0%, rgba(white,1) 50%, rgba(white,0) 50%, rgba(white,0) 100%),
    /* Fade */ linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    background-repeat: repeat-x;
    background-position: /* Jagged */ top center, top center, /* Fade */ top center;
    background-size: /* Jagged */ 13px 13px, 13px 13px, /* Fade */ 100% 1rem;
}


// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix() {
    &:before,
    &:after {
        display: table; // 2
        content: " "; // 1
    }
    &:after {
        clear: both;
    }
}
