// Tweaked copypasta from passit-frontend repo (after webpack built it)
.button {
    display:inline-block;
    position:relative;
    margin-bottom:7px;
    padding:10px 30px 5px;
    transform:translateY(0);
    border:0;
    outline:0;
    background-color:transparent;
    color:#413741;
    font-weight:600;
    text-decoration:none;
    cursor:pointer;
    -moz-appearance:none;
    appearance:none;
    box-sizing:padding-box;
}

.button:after {
    position:absolute;
    bottom:-4px;
    left:0;
    width:100%;
    height:4px;
    transform:scale(1);
    transform-origin:center top;
    content:"";
}

.button:hover:not([disabled]) {
    transform:translateY(-3px);
}

.button:hover:not([disabled]):after {
    transform:scaleY(1.75);
}

.button:active:not([disabled]) {
    transform:translateY(2px);
    transition:background-color .2s,border-color .2s,color .2s,transform 0 0;
}

.button:active:not([disabled]):after {
    transform:scaleY(.4);
    transition:transform 0 0;
}

.button[disabled] {
    color:#BED2D2;
}

.button--gray {
    background-color:#FFFFFF;
    color:#413741;
    box-shadow:0 6px 3px 0 rgba(0,0,0,.2),inset 0 0 0 0 #E9F2F2;
}

.button--gray--hover,.button--gray:hover:not([disabled]) {
    box-shadow:0 9px 6px 2px rgba(0,0,0,.15),inset 0 0 0 4px #E9F2F2;
}

.button--gray--hover:after,.button--gray:hover:not([disabled]):after {
    background-color:#66AFBA;
}

.button--gray:active:not([disabled]) {
    box-shadow:0 3px 3px 0 rgba(0,0,0,.2),inset 0 0 0 5px #E9F2F2;
}

.button--gray:after {
    background:none;
    background-color:#CCCCCC;
}

.button--primary {
    border:2px solid #BED2D2;
    border-bottom-color:#0092A8;
    background-color:#FFFFFF;
    color:#413741;
    box-shadow:0 6px 3px 0 rgba(0,0,0,.2),inset 0 0 0 0 #D3F6FF;
}

.button--primary--hover,.button--primary:hover:not([disabled]) {
    box-shadow:0 9px 6px 2px rgba(0,0,0,.15),inset 0 0 0 4px #D3F6FF;
}

.button--primary--hover:after,.button--primary:hover:not([disabled]):after {
    background-color:#006675;
}

.button--primary:active:not([disabled]) {
    box-shadow:0 3px 3px 0 rgba(0,0,0,.2),inset 0 0 0 5px #D3F6FF;
}

.button--primary:after {
    bottom:-5px;
    width:calc(100%+4px);
    margin:0 -2px;
    background:none;
    background-color:#0092A8;
}

.button--primary:active:not([disabled]),.button--primary:hover:not([disabled]) {
    border:2px solid #D3F6FF;
}

.button--deep-cerulean {
    background-color:#0092A8;
    color:#FFFFFF;
    box-shadow:0 6px 3px 0 rgba(0,0,0,.2),inset 0 0 0 0 #00BEDB;
}

.button--deep-cerulean--hover,.button--deep-cerulean:hover:not([disabled]) {
    box-shadow:0 9px 6px 2px rgba(0,0,0,.15),inset 0 0 0 4px #00BEDB;
}

.button--deep-cerulean--hover:after,.button--deep-cerulean:hover:not([disabled]):after {
    background-color:#006675;
}

.button--deep-cerulean:active:not([disabled]) {
    box-shadow:0 3px 3px 0 rgba(0,0,0,.2),inset 0 0 0 5px #00BEDB;
}

.button--deep-cerulean:after {
    background:none;
    background-color:#006675;
}

.button--green {
    background-color:#6CC780;
    color:#FFFFFF;
    box-shadow:0 6px 3px 0 rgba(0,0,0,.2),inset 0 0 0 0 #47B960;
}

.button--green--hover,.button--green:hover:not([disabled]) {
    box-shadow:0 9px 6px 2px rgba(0,0,0,.15),inset 0 0 0 4px #47B960;
}

.button--green--hover:after,.button--green:hover:not([disabled]):after {
    background-color:#39944D;
}

.button--green:active:not([disabled]) {
    box-shadow:0 3px 3px 0 rgba(0,0,0,.2),inset 0 0 0 5px #47B960;
}

.button--green:after {
    background:none;
    background-color:#39944D;
}

.button--green[disabled] {
    color:hsla(0,0%,100%,.5);
}

.button--large {
    font-size:1.2em;
}

.button--small {
    font-size:.8em;
}

.button--text {
    transform:translateY(2px);
    background-color:transparent;
    color:#413741;
    box-shadow:0 0 0 0 transparent,inset 0 0 0 0 #F2F2F2;
}

.button--text:after {
    display:none;
}

.button--text:hover:not([disabled]) {
    transform:translateY(2px);
    background-color:#FFFFFF;
    box-shadow:0 3px 5px 1px rgba(0,0,0,.2),inset 0 0 0 5px #F2F2F2;
}

.button--text-danger {
    box-shadow:0 0 0 0 transparent,inset 0 0 0 0 #B92855;
}

.button--text-danger:hover:not([disabled]) {
    background-color:#FFFFFF;
    box-shadow:0 3px 5px 1px rgba(0,0,0,.2),inset 0 0 0 5px #B92855;
}

.button__icon {
    display:inline-block;
    width:12px;
    height:12px;
    margin-right:5px;
}

.button__icon--no-text {
    width:16px;
    height:16px;
    margin-right:0;
}
