/* Webfont: Overpass-Bold */@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    src: url('/dist/fonts/Overpass-Bold.woff') format('woff');
}

/* Webfont: Overpass-BoldItalic */@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    src: url('/dist/fonts/Overpass-Bold-Italic.woff') format('woff');
}

/* Webfont: Overpass-Reg */@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
    src: url('/dist/fonts/Overpass-Regular.woff') format('woff');
}

/* Webfont: Overpass-Italic */@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 600;
    text-rendering: optimizeLegibility;
    src: url('/dist/fonts/Overpass-Italic.woff') format('woff');
}

/* Webfont: Overpass-Light */@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    src: url('/dist/fonts/Overpass-Light.woff') format('woff');
}

/* Webfont: Overpass-LightItalic */@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    src: url('/dist/fonts/Overpass-Light-Italic.woff') format('woff');
}

/* Webfont: Overpass-Mono-Regular */@font-face {
    font-family: 'Overpass Mono';
    font-weight: normal;
    text-rendering: optimizeLegibility;
    src: url('/dist/fonts/Overpass-Mono-Regular.woff') format('woff');
}
