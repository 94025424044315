.blog-header {
    margin-top: 70px;
    margin-bottom: 50px;
}

.blog-hero {
    padding: 40px 0 0;
    background-color: $color-botticelli;
    color: $color-matterhorn;
    font-size: 0;
    overflow: hidden;

    @include breakpoint('max', $screen-xs) {
        padding-top: 30px;
    }

    &__header {
        @include responsiveType($font-size-h2);
        margin: 0 0 15px;
        font-weight: 700;
        letter-spacing: -0.02em;
        line-height: 1;
    }

    &__image {
        height: 420px;
        margin-top: -50px;
        margin-left: 50%;
        transform: translateX(-50%);

        @include breakpoint('max', $screen-md) {
            height: 340px;
        }
        @include breakpoint('max', 599px) {
            height: 60vw;
            margin-top: -10vw;
        }
    }
}

.blog-loop {
    margin-top: 50px;

    @include breakpoint('max', $screen-sm) {
        margin-top: 30px;
    }

    &__post {
        margin: 25px 0;
    }
    &__post-title {
        line-height: 1.2em;
    }
    &__meta {
        display: block;
        margin: 5px 0 10px;
        color: $color-matterhorn;
        font-size: 0.85em;
        font-weight: 600;
        letter-spacing: -0.02em;
    }
}

.blog-pagination {
    margin-top: 50px;
    margin-bottom: 50px;

    &__item {
        &--older {
            float: right;
        }
        &--newer {
            float: left;
        }
        &--inactive {
            color: $color-botticelli;
            cursor: default;
        }
    }
}

.blog-post {
    &__header {
        margin-top: 50px;
    }
    &__title {

    }
    &__meta {
        display: block;
        margin: 5px 0 10px;
        color: $color-matterhorn;
        font-size: 0.85em;
        font-weight: 600;
        letter-spacing: -0.02em;
    }
    &__content {

    }
}
