.heading-main {
    color: $color-matterhorn;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1;

    @include breakpoint('max', $screen-md) {
        font-size: 36px;
    }

    &--pad-bottom {
        margin-bottom: 40px;

        @include breakpoint('max', $screen-md) {
            margin-bottom: 20px;
        }
    }
}

.heading-subheading {
    color: $color-matterhorn;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: -0.02em;
}

.heading-3 {
    color: #0092A8;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.02em;
}
